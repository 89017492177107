.modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    @apply z-40 bg-black/60;
}

.modal__container {
    min-width: 35vw;
    max-width: 1000px;
    max-height: 90vh;
    overflow-y: auto;
    box-sizing: border-box;
    @apply bg-white dark:bg-gray-900 rounded p-8;
}

.modal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @apply space-x-4;
}

.modal__title {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.25;
    box-sizing: border-box;
}

.modal__title h4 {
    font-size: xx-large;
}

.modal__close {
    background: transparent;
    border: 0;
}

.modal__header .modal__close::before { content: "\2715"; }

.modal__content {
    margin-top: 2rem;
    margin-bottom: 2rem;
    line-height: 1.5;
}

.modal__footer {
    @apply flex justify-between items-center;
}

.modal__btn * {
    pointer-events: none;
}

/**************************  \
  Demo Animation Style
\  **************************/
@keyframes mmfadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes mmfadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
}

@keyframes mmslideIn {
    from { transform: translateY(15%); }
    to { transform: translateY(0); }
}

@keyframes mmslideOut {
    from { transform: translateY(0); }
    to { transform: translateY(-10%); }
}

.micromodal-slide {
    display: none;
}

.micromodal-slide.is-open {
    display: block;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
    animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .modal__container {
    animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
    animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__container {
    animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

#last-popup .modal__container,
#no-regret-popup .modal__container,
#update-contract-form .modal__container,
#learners-edit-form .modal__container,
#managers-edit-form .modal__container,
#popup-slots-confirm .modal__container,
#add-comment .modal__container {
    max-width: 600px;
}

#modal-intro-tutorial .modal__container,
#modal-homework-tutorial .modal__container {
    min-width: 600px;
}

#modal-intro-tutorial-title,
#modal-homework-tutorial-title {
    margin: auto;
}

#advanced-search-form .modal__container {
    width: 900px;
}

#modal-reminder-form .modal__container {
    width: 700px;
}

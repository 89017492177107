/* Video */
.large.video-wrapper {
    padding-bottom: 54%;
}

.medium.video-wrapper {
    padding-bottom: 56.25%;
}

.video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    top: 0;
    right: 0;
}

.video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px 5px 0 0;
}

.video-wrapper iframe.wrapper-consultation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 7px;
}

/* fix a little bug: remove a white line on the side */
#player-welcome {
    width: 100.1%;
    height: 100.1%;
}

.video-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #77d6a8;
    z-index: 3;
    border-radius: 3px 3px 0 0;
}

.video-content .shape {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
}

.video-content .tutor {
    position: absolute;
    right: 4%;
    bottom: 0;
    height: 90%;
    width: auto;
}

.video-content p {
    position: absolute;
    bottom: 10%;
    right: 35%;
    color: #999;
    font-size: 12px;
    width: 150px;
}

.video-content .next-lesson {
    width: 17%;
    padding-bottom: 17%;
    background: #000;
    border-radius: 50%;
    position: absolute;
    top: 16%;
    right: 21%;
}

.video-content .next-lesson p {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 10px;
    color: #fff;
    font-size: 1.2rem;
    text-align: center;
    display: flex;
    align-items: center;
}

.video-content .next-lesson span {
    width: 100%;
}

.video-caption {
    position: absolute;
    color: #fff;
    z-index: 3;
    width: 50%;
    padding: 20px;
}

.video-caption .module-group {
    border: 1px solid #fff;
    border-radius: 5px;
    display: inline-block;
    padding: 0 10px;
}

.video-caption .lesson {
    font-size: 28px;
}

.video-wrapper .play-video {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    background: transparent;
    z-index: 10;
}

.video-wrapper .play-video i {
    line-height: 70px;
    position: relative;

    /* top: 50%; */
    display: block;
    margin: -35px auto 0;
    color: #fff;
    height: 70px;
    width: 70px;
    font-size: 16px;
    text-align: center;
    border-radius: 50%;
    background: rgb(0 0 0 / 50%);
    border: 0;
}

.video-wrapper .play-video:hover i {
    background: rgb(0 0 0 / 70%);
}

@media (max-width: 767px) {
    .video-caption {
        padding: 10px;
    }

    .video-caption .lesson {
        font-size: 1.4rem;
    }

    .video-caption .module {
        display: none;
    }
}

@media (max-width: 520px) {
    .video-caption .lesson {
        font-size: 1rem;
    }

    .video-caption .module-group {
        display: none;
    }

    .video-content p {
        bottom: 5px;
        position: relative;
        left: 20px;
        top: 50%;
        color: #000;
    }

    .video-content .next-lesson {
        display: none;
    }
}

@media (max-width: 320px) {
    .video-content p {
        display: none;
    }
}

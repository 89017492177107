/* DatePicker */
.flatpickr-day.selected,
.flatpickr-day.selected:hover,
.flatpickr-day.selected:focus,
.flatpickr-day.selected:active {
    background: #000;
    border-color: #000;
}

.flatpickr-calendar {
    border-radius: 1px;
}

/* Alter (flashbags) */
.alert {
    font-weight: 400;
    border-radius: 2px;
    margin-bottom: 5px;
    margin-top: 5px;
    padding: 15px;
}

.alert-div {
    position: fixed;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    width: 30%;
    z-index: 1000;
}

.alert-dismissable .close,
.alert-dismissible .close {
    position: absolute;
    top: 5px;
    right: 10px;
    color: inherit;
}

.alert-success {
    background-color: #dff0d8;
    border-color: #d6e9c6;
    color: #3c763d
}

.alert-info {
    background-color: #d9edf7;
    border-color: #bce8f1;
    color: #31708f
}

.alert-warning {
    background-color: #fcf8e3;
    border-color: #faebcc;
    color: #8a6d3b;
}

.alert-danger {
    background-color: #fff;
    border-color: #000;
    color: #ff364e;
}

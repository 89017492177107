.custom-scrollbar::-webkit-scrollbar {
    width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    border-radius: 80vh;
    background: #e5e5e5;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background: #000;
    border-radius: 100vh;
    border: 3px solid #000;
}

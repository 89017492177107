/* Pagination */
.pagination {
    display: flex;
    @apply space-x-2 justify-center;
}

.paginate_button.first {
    margin-left: 0;
    border-radius: 2px 0 0 2px;
}

.paginate_button.last {
    border-radius: 0 2px 2px 0;
}

.paginate_button.active {
    z-index: 2;
    @apply text-white bg-black border-black;
}

.paginate_button.disabled {
    pointer-events: none;
    @apply border-gray-300 text-gray-300 bg-white dark:bg-gray-900 dark:border-gray-700 dark:text-gray-700;
}

.paginate_button:hover,
.paginate_button:focus {
    color: white;
    cursor: pointer;
    text-decoration: none;
    background-color: #000;
    border-color: #000;
}

.paginate_button a:hover,
.paginate_button a:focus {
    @apply text-white bg-black border-black;
}

.paginate_button,
.pagination > li > a,
.pagination > li > span {
    padding: 6px 12px;
    position: relative;
    text-decoration: none;
    @apply text-black bg-white border border-black dark:bg-gray-900 dark:text-white dark:border-gray-700;
}

.dataTables_paginate .ellipsis,
.paginate_button {
    @apply mx-1;
}

.pagination > li > a:hover,
.pagination > li > a:focus,
.pagination > li > span:hover,
.pagination > li > span:focus {
    color: white;
    background-color: #000;
    border-color: #000;
}

.paginate_button.current,
.pagination > .active > a,
.pagination > .active > span {
    @apply text-black border-transparent dark:text-white;
}

.paginate_button.current:hover,
.paginate_button.current:focus,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
    color: white;
    background-color: #000;
    border-color: #000;
}

.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {
    @apply text-gray-200 bg-white border-gray-200 dark:bg-gray-900 dark:border-gray-700 dark:text-gray-700;
}

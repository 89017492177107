/* mini module progress */
.mini-module-progress {
    display: block;
    min-height: 40px;
    margin-bottom: 20px;
}

.mini-module-progress .icon {
    float: left;
    position: relative;
    height: 40px;
    width: 40px;
    margin-right: 10px;
}

.mini-module-progress canvas {
    margin: 0 auto;
}

.mini-module-progress .icon > p {
    margin: 0 auto;
    border-radius: 50%;
    border: 3px solid #ddd;
    height: 100%;
    width: 100%;
    text-align: center;
}

.mini-module-progress .icon > p > i {
    color: white !important;
    position: relative;
    display: block;
    top: 50%;
    transform: translateY(-50%);
}

.mini-module-progress .icon > p > span {
    position: relative;
    display: block;
    top: 50%;
    line-height: 12px;
    transform: translateY(-50%);
    @apply text-sm;
}

.mini-module-progress .icon > p > img {
    display: block;
    position: relative;
    height: 16px;
    top: 50%;
    margin: -7px 0 0 12px;
}

.mini-module-progress .description {
    font-size: 12px;
    height: 40px;
    display: table-cell;
    vertical-align: middle;
    color: #666;
}

.mini-module-progress:hover .description {
    color: #666;
    font-weight: 700;
}

.module-progress {
    height: 60px;
    width: 60px;
    float: right;
}

.module-progress p {
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid #eee;
    border-radius: 50%;
    box-sizing: border-box;
}

.module-progress p span {
    width: 100%;
    position: absolute;
    margin-top: -23px;
    top: 50%;
    color: #10928f;
    text-align: center;
    height: 50px;
    line-height: 50px;
    font-size: 1.5rem;
    font-weight: 500;
}

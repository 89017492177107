/* mobileMenu */

#show-menu {
    cursor: pointer;
    font-size: 30px;
    @apply py-6 text-black dark:text-white;
}

#hide-menu {
    cursor: pointer;
    font-size: 30px;
    @apply p-6 text-black dark:text-white;
}

#nav-mobile {
    display: none;
    margin: 0;
    padding-top: 60px;
    border: none;
    z-index: 30;
    width: 100%;
    min-height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    overflow-y: auto;
    scroll-behavior: auto;
    @apply bg-white dark:bg-black overflow-y-auto scroll-auto;
}

#nav-mobile button,
#nav-mobile a,
.dropdown-container a {
    padding: 6px 8px 6px 16px;
    text-align: left;
    display: block;
    font-size: 1.6rem;
    width: 100%;
}

#nav-mobile button,
#nav-mobile a {
    @apply text-black dark:text-white;
}

#nav-mobile .dropdown-container a {
    @apply text-gray-700 dark:text-gray-400 px-8;
}

#nav-mobile a:hover,
#nav-mobile button:hover,
#nav-mobile .active {
    @apply bg-gray-200 dark:bg-gray-800;
}

.dropdown-container {
    display: none;
    @apply bg-gray-100 dark:bg-gray-900;
}

.nav-link {
    @apply inline-block cursor-pointer font-normal text-black text-center px-4 py-3 rounded transition dark:text-white;
}

.nav-link:hover {
    @apply bg-gray-100 text-black scale-104 dark:bg-gray-700 dark:text-white;
}

.nav-link.active > span {
    @apply underline decoration-2 underline-offset-8;
}

.nav-link .notification {
    bottom: -5px;
    right: -10px;
    position: absolute;
    text-align: center;
    height: 18px;
    line-height: 20px;
    background: red;
    border-radius: 1px;
    font-size: 10px;
    padding: 0 5px;
    transform: rotate(-5deg);
}

.nav-submenu ul::before {
    right: 40px;
}

#header-score .tailwind-button:hover {
    @apply bg-framboise-500 text-white border-framboise-500;
}

#header-logout {
    width: 30px;
    height: 35px;
    font-size: 18px;
    line-height: 35px;
}

#header-logout a:hover {
    @apply text-framboise-500;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown > .nav-link {
    @apply scale-100;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    min-width: 160px;
    @apply bg-white absolute shadow z-10 rounded border dark:text-white dark:bg-gray-900 dark:border-gray-700;
}

/* Links inside the dropdown */
.dropdown-content a {
    @apply block text-black text-left no-underline px-4 py-3 dark:text-white;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    @apply bg-gray-100 dark:bg-gray-700;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content,
.dropdown:focus .dropdown-content,
.dropdown:focus-within .dropdown-content {
    display: block;
}

/* Mode switcher */
.margin-switcher,
.margin-impersonator {
    margin-top: 34px;
}

.margin-switcher.margin-impersonator {
    margin-top: 60px;
}

.switcher-btn {
    @apply inline-block px-2 ml-2 font-medium text-white border border-solid border-white text-xs transition;
}

.switcher-btn:hover,
.switcher-btn:active,
.switcher-btn:focus {
    @apply text-black bg-white transform-none;
}

.vertical-menu > ul > li {
    @apply pl-8 py-2 pr-2 font-medium hover:font-semibold hover:bg-gray-100 dark:text-white dark:hover:bg-gray-800;
}

.vertical-menu > ul > li > button {
    @apply font-medium hover:font-semibold;
}

.vertical-menu .tab-title {
    @apply w-full flex cursor-pointer justify-between;
}

.vertical-menu .tab-content {
    max-height: 0;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    transition: all 0.8s ease-in-out;
    @apply flex flex-col pl-8 font-medium;
}

.vertical-menu .tab-content a {
    @apply pt-2 hover:font-semibold;
}

.vertical-menu .tab-wrapper.active {
    @apply bg-gray-100 dark:bg-gray-800;
}

.vertical-menu .tab-wrapper.active .tab-title {
    @apply bg-gray-100 dark:bg-gray-800;
}

.vertical-menu .tab-wrapper.active .tab-content {
    max-height: 400px;
    visibility: visible;
    opacity: 1;
    transition: all 0.8s ease-in-out;
}

.vertical-menu .tab-wrapper .tab-title .open-icon {
    transition: transform 0.4s ease-in-out;
    transform: rotate(0deg);
}

.vertical-menu .tab-wrapper.active .tab-title .open-icon {
    transition: transform 0.4s ease-in-out;
    transform: rotate(180deg);
}

/* Notification */

.notification-badge {
    position: absolute;
    font-size: 10px;
    height: 14px;
    min-width: 14px;
    padding: 0 2px;
    top: 20%;
    left: 55%;
    @apply bg-red-500 text-white font-bold rounded-full;
}

.notification-details.new .title {
    @apply font-bold;
}

.notification-list {
    width: 400px;
}

#notification-display {
    width: 800px;
    @apply border-none;
}

.notification-display-content {
    max-height: 800px;
    @apply outline-none overflow-y-auto;
}

.notification-display-content a:not(.tailwind-button) {
    @apply underline;
}

.notification-display-content ul,
.notification-display-content ol {
    @apply list-inside;
}

.notification-display-content ul {
    @apply list-disc;
}

.notification-display-content ol {
    @apply list-decimal;
}

@media (max-width: 767px) {
    .margin-switcher {
        margin-top: 60px;
    }
}

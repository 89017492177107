/* Administrator */
#administrator-page #page {
    overflow: auto;
}

#administrator-table {
    width: 100%;
    border-collapse: collapse;
    color: #666;
    min-width: 800px;
}

#administrator-table tr {
    height: 50px;
}

#administrator-table th {
    font-size: 12px;
    font-weight: 300;
    text-align: center;
    height: 40px;
    vertical-align: top;
}

#administrator-table th span {
    display: block;
    color: #999;
    font-size: 10px;
}

#administrator-table .column {
    height: 70px;
    margin-bottom: 5px;
    border-radius: 5px;
    cursor: pointer;
    @apply dark:border dark:border-gray-700;
}

#administrator-table .column:hover td,
#administrator-table .column.active td {
    @apply text-white bg-black dark:bg-gray-800;
}

#administrator-table .column.active .rank {
    border-radius: 5px 0 0;
}

#administrator-table .column.active .more {
    border-radius: 0 5px 0 0;
}

#administrator-table .column td {
    text-align: center;
    font-size: 18px;
    @apply bg-gray-50 dark:bg-gray-900 dark:text-white;
}

#administrator-table .spacer {
    height: 10px;
}

#administrator-table .modules {
    @apply bg-white dark:bg-gray-900 dark:border dark:border-gray-700;
}

#administrator-table .modules td {
    padding: 30px 20px;
    border-radius: 0 0 5px 5px;
}

#administrator-table .column .rank {
    border-radius: 5px 0 0 5px;
    width: 7%;
    font-size: 30px;
    color: #ccc;
    font-weight: 500;
}

#administrator-table .column .name {
    width: 18%;
    color: #000;
    text-align: left;
    @apply text-sm;
}

#administrator-table .rank a {
    float: left;
}

#administrator-table .company {
    display: block;
    color: #999;
    font-size: 10px;
    font-style: italic;
    margin-top: 3px;
}

#administrator-table .views {
    width: 10%;
}

#administrator-table .latest {
    width: 15%;
}

#administrator-table .time {
    width: 15%;
}

#administrator-table .points {
    width: 7%;
}

#administrator-table .quizz {
    width: 10%;
}

#administrator-table .themes {
    width: 8%;
}

#administrator-table .themes > * {
    @apply mx-auto;
}

#administrator-table .themes i {
    position: relative;
    font-size: 20px;
    margin-right: 8px;
}

#administrator-table .themes i i {
    position: absolute;
    top: 0;
    left: 6px;
    line-height: 17px;
    font-size: 6px;
    color: #fff;
    font-weight: 900;
}

#administrator-table .more {
    border-radius: 0 5px 5px 0;
    width: 10%;
    text-align: center;
    color: #000;
}

#administrator-table .more i {
    display: inline-block;
    transition: 0.5s;
}

#administrator-table tr:hover .more i {
    transform: rotate(90deg);
}

.sortable-tr {
    cursor: move;
}

.sortable-tr.over {
    background-color: #f9f9f9;
}

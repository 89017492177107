#step-text-render ul {
    @apply list-inside list-disc;
}

#step-text-render ol {
    @apply list-inside list-decimal;
}

td ul {
    @apply list-inside list-disc;
}

td ol {
    @apply list-inside list-decimal;
}

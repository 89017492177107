/* easyProgress */

.easyProgress {
    position: relative;
}

.easyProgress canvas {
    position: absolute;
    top: 0;
    left: 0;
}

/* Set classes to access colors */
.yellow-bg {
    background-color: #f8e71c;
    @apply text-black;
}

.framboise-bg {
    background-color: #ff4c5d;
    @apply text-white;
}

.yellow-text {
    color: #f8e71c;
}

.green-text {
    color: #10b981;
}

.blue-text {
    color: #363ab9;
}

.red-text {
    color: #ff364e;
}

.black-bg {
    background-color: black;
    color: white;
}

.black-bg a:hover {
    color: #44ceaf;
}

.grey-text {
    color: #ddd;
}

.darkgrey-text {
    color: #838383;
}

/* Helpers & Utilities */

.fa-margin {
    @apply mr-2;
}

.fa-margin-right {
    @apply mr-2;
}

.fa-margin-left {
    @apply ml-2;
}

.noscroll {
    width: 100%;
    height: 100%;
    position: fixed;
    overflow: hidden;
}

.removed {
    display: none;
}

.no-border {
    border: none;
}

.grayed {
    @apply text-gray-400 !important;
}

.d-inline-block {
    display: inline-block;
}

.fa-big-margin-right {
    margin-right: 20px;
}

.margin-right {
    margin-right: 10px;
}

.margin-bottom {
    margin-bottom: 20px;
}

.margin-top {
    margin-top: 20px;
}

.margin-top-l {
    margin-top: 30px;
}

.margin-top-xl {
    margin-top: 50px;
}

.margin-top-xxl {
    margin-top: 100px;
}

.no-margin {
    margin: 0 !important;
}

.margin-top-s {
    margin-top: 10px;
}

.reverse {
    color: white !important;
}

.no-padding {
    padding: 0;
}

.no-left-padding {
    padding-left: 0;
}

.no-right-padding {
    padding-right: 0;
}

/* Used for the Admin Part */
.box-shadow-no-top {
    background: rgb(255 255 255 / 55%);
    border-radius: 5px;
    box-shadow: 0 20px 30px rgb(0 0 0 / 4%);
}

.inline li {
    display: inline-block;
    margin-right: 10px;
}

span.danger {
    color: #e9394d;
    font-weight: bold;
}

@media (max-width: 992px) {
    .no-padding {
        padding: inherit;
    }

    .no-left-padding {
        padding-left: inherit;
    }

    .no-right-padding {
        padding-right: inherit;
    }

    .margin-top-xxl,
    .margin-top-xl,
    .margin-top-l {
        margin-top: 40px;
    }
}

@media (max-width: 767px) {
    .margin-top-xxl,
    .margin-top-xl,
    .margin-top-l {
        margin-top: 10px;
    }
}

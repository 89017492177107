/* Reset */
html {
    font-size: 100%;
}

html,
body {
    font-family:
        archia,
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        Helvetica,
        Arial,
        sans-serif,
        "Apple Color Emoji",
        "Segoe UI Emoji",
        "Segoe UI Symbol";
}

a {
    @apply cursor-pointer;
}

.dataTables_wrapper {
    position: relative;
}

/* DataTables Custom */
div.dataTables_info {
    padding: 5px 15px;
    @apply text-gray-400;
}

div.dataTables_paginate {
    float: right;
}

div.dataTables_paginate,
div.dataTables_info {
    display: inline-block;
    margin-top: 30px !important;
}

div.dataTables_filter {
    text-align: left !important;
    margin-bottom: 25px;
}

div.dataTables_filter input {
    @apply dark:bg-gray-900 dark:border-gray-700;
}

div.dataTables_filter label {
    position: relative;
    display: inline-block;
}

div.dataTables_filter label .fa-icon-input {
    display: block;
    position: absolute;
    top: 50%;
    right: 12px;
    color: rgb(163 163 163);
    z-index: 10;
    transform: translateY(-50%);
    background-color: transparent;
    padding: 1px;
}

table.dataTable thead .sorting::after {
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font-family: "Font Awesome 5 Free";
    font-size: 0.6em;
    margin-left: 10px;
    content: "\f077";
}

table.dataTable thead .sorting_desc::after {
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font-family: "Font Awesome 5 Free";
    font-size: 0.6em;
    margin-left: 10px;
    line-height: 3.8em;
    content: "\f077";
    opacity: 0.7;
}

table.dataTable thead .sorting_asc::after {
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font-family: "Font Awesome 5 Free";
    font-size: 0.6em;
    margin-left: 10px;
    line-height: 3.8em;
    content: "\f078";
    opacity: 0.7;
}

table.dataTable thead .sorting::before,
table.dataTable thead .sorting_desc::before,
table.dataTable thead .sorting_asc::before {
    content: none;
}

div.dataTables_processing {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    padding: 10px;

    @apply bg-white border border-black dark:bg-gray-900 dark:border-gray-700 dark:text-white;
}

#table-filters-container {
    display: inline-block;
    color: #333;
    border-radius: 5px;
}

.table-filters {
    overflow: hidden;
    height: 40px;
    position: relative;
    display: block;
}

.table-filters select {
    /*    background-color: rgb(255, 255, 255) !important; */
    max-height: 40px;
    padding: 10px;
    margin-left: 20px;
    color: #444 !important;
    font-size: 1em !important;
    border-width: 1px !important;
    border-style: dashed !important;
    border-color: rgb(0 0 0 / 10%) !important;
    border-image: initial !important;
    border-radius: 0.25rem !important;
    min-width: 170px;
    position: relative;
    appearance: none;
    display: inline-block;
}

#learner-table_filter > label {
    float: left;
}

#learner-table_filter div#table-filters-container label {
    font-weight: 400;
}

@media (max-width: 767px) {
    div.dataTables_filter {
        margin-top: 20px;
    }
}

.tailwind-button {
    @apply
        transform transition-all inline-flex items-center justify-center
        px-4 py-3 text-sm font-medium rounded-sm
        cursor-pointer border border-solid border-transparent hover:scale-104 space-x-3;
}

.tailwind-button:hover,
.tailwind-button:active,
.tailwind-button:visited,
.tailwind-button:focus {
    text-decoration: none;
}

.tailwind-button.outlined {
    @apply border-black dark:border-white;
}

/** States **/
.tailwind-button.disabled {
    @apply cursor-not-allowed opacity-30 transform-none;
}

/** Sizes **/
.tailwind-button.small {
    @apply px-2 py-1 text-xs space-x-1;
}

.tailwind-button.large {
    @apply px-7 py-5 text-base;
}

/** Variants **/

/* Primary */
.tailwind-button.primary {
    @apply text-white bg-black border-black dark:bg-white dark:text-black dark:border-white;
}

.tailwind-button.primary.outlined {
    @apply text-black bg-white border-black dark:bg-gray-900 dark:border-gray-700 dark:text-white;
}

.tailwind-button.primary.outlined:hover {
    @apply bg-gray-100 dark:bg-gray-800;
}

/* Light */
.tailwind-button.light {
    @apply text-black bg-white border-white dark:bg-gray-700 dark:border-gray-700 dark:text-white;
}

.tailwind-button.light.outlined {
    @apply text-white bg-transparent border-gray-600;
}

.tailwind-button.light.outlined:hover {
    @apply bg-gray-600;
}

.tailwind-button.light.outlined.disabled:hover {
    @apply bg-black;
}

/* Framboise */
.tailwind-button.framboise {
    @apply text-white bg-framboise-500 border-framboise-500;
}

.tailwind-button.framboise.outlined {
    @apply text-framboise-500 bg-transparent border-framboise-500;
}

.tailwind-button.framboise:hover {
    @apply bg-framboise-400;
}

.tailwind-button.framboise.outlined:hover {
    @apply bg-framboise-100;
}

/* Ananas */
.tailwind-button.ananas {
    @apply text-black bg-ananas-500;
}

.tailwind-button.ananas:hover {
    @apply bg-ananas-300;
}

/* Dark */

.tailwind-button.dark.outlined {
    @apply text-black bg-transparent border-black;
}

.tailwind-button.dark.outlined:hover {
    @apply bg-black text-white;
}

/* Link */

.tailwind-button.link:hover {
    @apply shadow;
}

.tailwind-button.link.disabled:hover {
    @apply shadow-none;
}

.tailwind-button.inverted:hover {
    @apply text-white bg-black;
}

.tailwind-button.framboise.inverted:hover {
    @apply text-white bg-black border-black;
}

/* .tailwind-dropdown:focus-within button, */

/* .r-tailwind-dropdown.open button { */

/*    @apply shadow bg-white dark:bg-gray-700; */

/* } */

.tailwind-dropdown:focus-within .tailwind-dropdown-menu,
.tailwind-dropdown.open .tailwind-dropdown-menu,
.r-tailwind-dropdown.open .tailwind-dropdown-menu {
    opacity: 1;
    transform: translate(0) scale(1);
    visibility: visible;
}

.waves-container {
    padding-top: 25px;
}

.waves-section {
    @apply py-4;
}

h1.waves-title {
    @apply inline-block text-3xl font-bold text-framboise-500 tracking-wider;
}

a.waves-back-link,
span.waves-back-link {
    color: #333;
    @apply inline-block text-3xl font-bold tracking-wider dark:text-white;
}

a.waves-back-link:hover {
    color: #ff364e;
}

h2.waves-subtitle {
    margin-bottom: 20px;
    @apply text-2xl tracking-wide text-black dark:text-white;
}

h3.waves-subtitle {
    font-weight: 200;
    font-size: 1em;
    letter-spacing: 1.07px;
    margin-bottom: 20px;
    @apply text-black dark:text-white;
}

.waves-btn {
    letter-spacing: 0.4px;
    display: inline-block;
    min-height: 50px;
    padding: 16px 60px;
    color: white;
    font-weight: 700;
    border-radius: 2px;
    cursor: pointer;

    /* text-decoration: underline; */
    transition: all 0.1s ease-in-out;

    @apply text-sm;
}

.waves-btn:hover {
    /* text-decoration: none; */
    transform: scale(1.04);
    box-shadow: 0 0 30px rgb(0 0 0 / 10%);
}

.waves-btn.form-height {
    min-height: 40px;
}

.waves-btn.max-width {
    width: 100%;
}

a.waves-btn:hover,
a.waves-btn:active,
a.waves-btn:visited,
.waves-btn:focus {
    text-decoration: none;
}

.waves-btn.small-width {
    padding: 14px 25px;
    min-height: inherit;
}

.waves-btn.small {
    padding: 10px 20px;
    min-height: inherit;
    color: #000;
    font-weight: 400;
    font-size: 15px;
}

.waves-btn.primary {
    color: #000;
    border: 1px solid #000;
    background-color: #fff;
}

.waves-btn.primary:hover,
.waves-btn.primary:focus {
    color: white;
    background-color: #000;
}

.waves-btn.secondary {
    color: #000;

    /* border: 1px solid #ddd; */
    box-shadow: 0 0 20px rgb(0 0 0 / 10%);

    /* background-color: rgba(74, 59, 133, 0.06); */
}

.waves-btn.secondary:hover,
.waves-btn.secondary:focus {
    box-shadow: 0 0 30px rgb(0 0 0 / 10%);

    /* background-color: rgba(74, 59, 133, 0.1); */
}

.waves-btn.third {
    color: white;
    background-color: #000;
}

.waves-btn.third:hover,
.waves-btn.third:focus {
    background-color: #000;
}

.waves-btn.fourth {
    color: white;
    background-color: #000;
}

.waves-btn.fourth:hover,
.waves-btn.fourth:focus {
    color: #000;
    background-color: white;
}

.waves-btn.white {
    color: #000;
    background-color: white;
}

.waves-btn.white:hover,
.waves-btn.white:focus {
    color: #111;
    border: 1px solid #000;
}

.waves-btn.danger {
    background-color: #d8d9dd;
}

.waves-btn.danger:hover,
.waves-btn.danger:focus {
    color: white;
    background-color: #e9394d;
}

.waves-btn.in-progress {
    cursor: wait;
}

.waves-btn.cta {
    background-color: #ff364e;
}

.waves-btn.cta:hover,
.waves-btn.cta:focus {
    color: white;
    background-color: #ff364e;
}

.waves-btn.confirm {
    background-color: #db374c;
}

.waves-action-btn {
    @apply inline-block rounded-sm transition py-1 px-3 bg-gray-100 dark:bg-gray-800;
}

button.waves-action-btn {
    line-height: unset !important;
    vertical-align: unset !important;
}

.waves-action-btn:hover,
.waves-action-btn:focus {
    background-color: #000;
    color: white !important;
    transform: scale(1.04);
    box-shadow: 0 0 15px rgb(0 0 0 / 10%);
}

.waves-panel-btn {
    color: #ccc;
    text-align: center;
    display: inline-block;
    border: 1px solid #ddd;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    transition: all 0.2s ease 0s;
}

.waves-panel-btn:hover,
.waves-panel-btn:focus {
    border-color: #77d6a8;
    color: #77d6a8;
}

.waves-panel-btn-title {
    font-weight: 700;
    font-size: 0.9em;
}

.waves-panel-btn-icon {
    font-size: 1.9em;
}

.waves-aside-actions {
    padding: 30px 50px;
}

.waves-helper-btn {
    color: #e0e0e0;
}

.waves-helper-btn:hover,
.waves-helper-btn:focus {
    color: #000;
}

.waves-stat-container {
    display: inline-block;
    margin-bottom: 5px;
}

.waves-stat {
    font-weight: 400;
    font-size: 2em;
    color: #000;
    letter-spacing: 1.24px;
}

.waves-stat-desc {
    font-size: 0.8em;
    color: #a4a4a4;
    letter-spacing: 0.53px;
}

.waves-table-container {
    overflow-x: auto;
}

.waves-table-loader {
    text-align: center;
    padding-top: 80px;
    @apply text-black dark:text-white;
}

.waves-table-loader p {
    font-size: 16px;
    margin-top: 10px;
    font-weight: 300;
}

.waves-table {
    width: 100%;
    max-width: 100%;
    font-weight: 300;
    text-align: left;
    @apply text-sm text-black bg-white/50 dark:bg-gray-900 dark:text-white;
}

.waves-table-responsive {
    min-width: 700px;
}

.waves-table-auto {
    min-width: auto;
}

.homeadmin-table {
    font-size: 17px;
}

.waves-table th,
.waves-table th > a {
    @apply text-black dark:text-white !important;
}

.waves-table th,
.waves-table td {
    vertical-align: middle;
    padding: 10px;
    @apply border-b dark:border-gray-700;
}

.waves-table-infos {
    padding: 0 15px;
    margin: 0;
    width: fit-content;
}

.waves-table-infos th,
.waves-table-infos td {
    padding-left: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
}

.waves-table-infos th {
    width: 150px;
}

#all-users .waves-table th,
#all-users .waves-table td {
    padding: 10px;
    max-width: 240px;
}

.homeadmin-table th,
.homeadmin-table td {
    padding: 10px 0;
}

.highlight-table tbody tr:hover {
    @apply bg-gray-100 dark:bg-gray-700;
}

.waves-table th.actions,
.waves-table td.actions {
    min-width: 140px;
}

.waves-table tr:last-child {
    border-bottom: none;
}

.waves-table thead th {
    padding: 10px;
    vertical-align: middle;
    border-bottom: 2px solid #000;
}

.sortable-list thead th,
.sortable-list tbody td {
    padding-left: 0;
}

.sortable-list {
    margin-top: 0;
    margin-bottom: 0;
}

.mini-table thead tr th {
    padding-right: 0 !important;
    @apply text-sm;
}

.mini-table thead .sorting_asc::after {
    display: none !important;
}

.waves-section-2 {
    margin-bottom: 60px;
}

.help-bubble > div {
    border: 1px dashed #eee;
    border-radius: 5px;
    background: rgb(255 255 255 / 100%);
    height: 70px;
    max-width: 250px;
}

.help-bubble-image {
    width: 60px;
    margin-top: 4px;
}

.help-bubble-text {
    padding-top: 10px;
}

.waves-stat-box {
    padding: 10px 1px;
    border-radius: 5px;
    margin-bottom: 10px;
    text-align: center;
    line-height: 12px;
    @apply text-black dark:text-white;
}

.waves-stat-box > div > i {
    opacity: 0.2;
    font-size: 50px;
}

.waves-stat-box b {
    line-height: 22px;
}

.waves-stat-box > div:first-of-type {
    margin-bottom: 12px;
}

.waves-action-box {
    color: #999;
    background-color: rgb(255 255 255 / 70%);
    padding: 20px 1px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 20px;
    margin-bottom: 10px;
    text-align: center;
    line-height: 0.9em;
}

.waves-action-box:hover {
    color: #ff364e;
    border: 1px solid #ff364e;
}

.waves-progress {
    /* border: 1px solid; */
    border-radius: 2px;
    font-size: 10px;
    background-color: #eee;
    height: 10px;
    max-width: 80px;
}

.waves-progress-bar {
    background-color: #ff364e;
    border-radius: 2px;
    height: 100%;
}

/* Waves card */

.waves-small-card {
    padding: 20px;
    z-index: 2;
    position: relative;
    @apply border bg-gray-50 rounded transition dark:bg-gray-900 dark:border-gray-700;
}

.waves-card-header {
    text-align: center;
    margin-bottom: 10px;
}

.waves-card-header h3 {
    font-size: 1.17em;
    font-weight: bold;
}

.waves-card-body {
    text-align: center;
    margin-bottom: 15px;
}

.waves-card-body h5 {
    font-weight: bold;
}

.waves-card-round-btn {
    border-radius: 1px;
    width: 40px;
    height: 40px;
    display: block;
    position: relative;
    @apply flex justify-center items-center text-center transition;
}

.waves-card-round-btn:hover {
    transform: scale(1.15);
}

.waves-card-round-btn:hover > i {
    color: #ff364e;
}

.waves-small-card:hover {
    box-shadow: 0 0 20px 0 rgb(0 0 0 / 7%);
    transform: scale(1.05);
    position: relative;
    z-index: 100;
    @apply border-gray-200;
}

.waves-card-dropdown {
    position: absolute;
    border: 1px solid #efefef;
    border-radius: 2px;
    background: white;
    padding: 5px;
    font-size: 12px;
    min-width: 200px;
    left: -50%;
    top: 45px;
    z-index: 100;
}

.waves-help-text-label {
    font-size: 0.85em;
    color: #bcb9b7;
}

#waves-ajax-response {
    color: rgb(74 59 133);
    font-size: 1.6rem;
    border: 1px solid rgb(74 59 133 / 24%);
    background: rgb(74 59 133 / 8%);
    border-radius: 8px;
    padding: 25px 30px;
    line-height: 30px;
}

.card-stat {
    font-weight: 500;
    @apply text-sm;
}

.card-stat-label {
    font-weight: 300;
    @apply text-xs;
}

.waves-small-card.expired .card-stat-label.expiration {
    color: #ff364e;
    font-weight: 500;
}

@media (max-width: 992px) {
    .waves-container {
        padding: 30px;
    }

    .waves-panel-btn-icon {
        font-size: 3em;
    }

    .waves-btn-primary,
    .waves-btn-danger {
        float: none !important;
    }

    .waves-aside-actions {
        padding: 10px 20px;
    }
}

@media (max-width: 767px) {
    h1.waves-title,
    a.waves-back-link,
    span.waves-back-link {
        font-size: 1.8em;
    }

    .waves-panel-btn {
        margin-bottom: 20px;
        max-width: 300px;
    }

    .waves-stat-container {
        padding-left: 10px;
    }

    .waves-btn.pull-right {
        float: left !important;
        margin-bottom: 10px;
    }
}

@font-face {
    font-family: archia;
    src: url("../../fonts/01.Archia-Thin-webfont/archia-thin-webfont.eot");
    src:
        url("../../fonts/01.Archia-Thin-webfont/archia-thin-webfont.eot?#iefix") format("embedded-opentype"),
        url("../../fonts/01.Archia-Thin-webfont/archia-thin-webfont.woff2") format("woff2"),
        url("../../fonts/01.Archia-Thin-webfont/archia-thin-webfont.woff") format("woff"),
        url("../../fonts/01.Archia-Thin-webfont/archia-thin-webfont.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: archia;
    src: url("../../fonts/03.Archia-Regular-webfont/archia-regular-webfont.eot");
    src:
        url("../../fonts/03.Archia-Regular-webfont/archia-regular-webfont.eot?#iefix") format("embedded-opentype"),
        url("../../fonts/03.Archia-Regular-webfont/archia-regular-webfont.woff2") format("woff2"),
        url("../../fonts/03.Archia-Regular-webfont/archia-regular-webfont.woff") format("woff"),
        url("../../fonts/03.Archia-Regular-webfont/archia-regular-webfont.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: archia;
    src: url("../../fonts/04.Archia-Medium-webfont/archia-medium-webfont.eot");
    src:
        url("../../fonts/04.Archia-Medium-webfont/archia-medium-webfont.eot?#iefix") format("embedded-opentype"),
        url("../../fonts/04.Archia-Medium-webfont/archia-medium-webfont.woff2") format("woff2"),
        url("../../fonts/04.Archia-Medium-webfont/archia-medium-webfont.woff") format("woff"),
        url("../../fonts/04.Archia-Medium-webfont/archia-medium-webfont.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: archia;
    src: url("../../fonts/06.Archia-Bold-webfont/archia-bold-webfont.eot");
    src:
        url("../../fonts/06.Archia-Bold-webfont/archia-bold-webfont.eot?#iefix") format("embedded-opentype"),
        url("../../fonts/06.Archia-Bold-webfont/archia-bold-webfont.woff2") format("woff2"),
        url("../../fonts/06.Archia-Bold-webfont/archia-bold-webfont.woff") format("woff"),
        url("../../fonts/06.Archia-Bold-webfont/archia-bold-webfont.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

.grey-icon {
    @apply text-gray-300 dark:text-gray-700;
}

.small {
    @apply text-xs;
}

.form-subtitle {
    @apply text-xl font-bold leading-8;
}

.datepicker {
    @apply mt-1 w-full font-light rounded-sm block w-full h-10 px-4 border border-gray-400;
}

/* Form */
label.required::after {
    content: " *";
    @apply text-framboise-500;
}

.form-control {
    @apply w-full rounded bg-white border dark:bg-gray-900 dark:text-white dark:border-gray-700 transition;
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0;
}

.form-control:focus {
    @apply border-gray-700 dark:border-gray-100;
}

.form-control::placeholder {
    @apply opacity-100 dark:text-white;
}

.form-control:disabled,
.form-control[readonly] {
    /*  background-color: #eceeef; */
    opacity: 1;
}

.form-control:disabled {
    cursor: not-allowed;
}

select.form-control:not([size]):not([multiple]) {
    height: calc(3.875rem + 2px);
}

select.form-control:focus::-ms-value {
    color: #000;
    background-color: #fff;
}

.control-label {
    margin: 10px 0 4px;
    font-size: 0.7em;
    color: #525252;
}

.form-control-lg,
.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
    padding: 0.95rem 1rem;
    font-size: 1.4rem;
    line-height: 1.5;
    border-radius: 0.25rem;
}

select.form-control-lg:not([size]):not([multiple]),
.input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > select.input-group-addon:not([size]):not([multiple]),
.input-group-lg > .input-group-btn > select.btn:not([size]):not([multiple]) {
    height: calc(3.875rem + 2px);
}

.form-group {
    margin-bottom: 1rem;
}

.has-error > input {
    border-color: #e9394d;
}

.help-block {
    font-size: 0.9em;
    color: #e9394d;
}

/* Error */
.has-error {
    border-color: #e9394d !important;
}

.form-error {
    color: #e9394d !important;
}

[type="checkbox"],
[type="radio"] {
    color: #000;
    cursor: pointer;
}

[type="text"]#post-search {
    border-width: 0;
}

.toggle-switch {
    @apply inline-flex items-center cursor-pointer;
}

.toggle-switch input[type="checkbox"] {
    @apply absolute opacity-0 w-0 h-0;
}

.toggle-switch .toggle-switch-inner {
    @apply block w-10 h-6 bg-gray-400 rounded-full shadow-inner;
}

.toggle-switch .toggle-switch-pill {
    @apply absolute block w-4 h-4 mt-1 ml-1 bg-black rounded-full shadow inset-y-0 left-0 transition-transform
        duration-300 ease-in-out;
}

.toggle-switch .toggle-switch-label {
    @apply mr-2 text-sm;
}

.toggle-switch.toggled .toggle-switch-pill {
    @apply transform translate-x-full bg-white;
}

.toggle-switch.toggled .toggle-switch-inner {
    @apply bg-black;
}

input[type="checkbox"],
label[for] {
    cursor: pointer;
}

/* Select2 custom */
.select2-selection {
    border: 1px #000 solid;
}

.select2-container {
    margin-top: 4px !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: black;
    font-size: 0.875rem;
    font-weight: 500;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    line-height: 20px !important;
}

.select2-container--default .select2-selection--multiple {
    border-radius: 0.125rem !important;
}

.dark .select2-container--default .select2-selection--single .select2-selection__rendered {
    @apply text-gray-700;
}

span.select2-selection.select2-selection--single {
    height: 39px !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 30px !important;
    margin-right: 10px !important;
    background-color: transparent !important;
}

.dark .select2-container--default .select2-selection--single .select2-selection__arrow {
    background-color: transparent !important;
}

.select2-container--default .select2-selection--single[aria-expanded="true"] .select2-selection__arrow {
    height: 38px !important;
}

.select2-container--default .select2-selection--single {
    border-radius: 0.125rem !important;
    @apply border-black dark:border-gray-700;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    border-radius: 1px;
    @apply dark:border-gray-700;
}

.select2-container--default .select2-selection--single[aria-expanded="true"] .select2-selection__arrow b {
    border: solid black;
    border-width: 2px 0 0 2px;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    border-radius: 1px;
}

[type="search"] {
    border: 2px solid #ddd;
    line-height: 1.25rem;
    font-size: 0.875rem;
    width: 240px;
}

[type="search"]:focus {
    border: 2px solid #ddd;
}

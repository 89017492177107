#cke_userbundle_note_note {
    margin-top: 20px;
}

.cke_chrome {
    border: 0 !important;
}

.cke_top,
.cke_bottom {
    border-color: #eee !important;
    @apply dark:bg-gray-900 dark:text-white dark:border-gray-700 !important;
}

.cke_reset_all,
.cke_reset_all *,
.cke_reset_all a,
.cke_reset_all textarea {
    @apply dark:text-white !important;
}

a.cke_dialog_ui_button {
    @apply dark:bg-gray-900 dark:text-white dark:border-gray-700 !important;
}

.cke_inner {
    @apply border-2 border-solid border-gray-200 dark:border-gray-700 !important;
}

.cke_editable {
    @apply dark:bg-gray-700 !important;
}

.cke_dialog_body,
.cke_dialog_title,
.cke_dialog_tab,
.cke_dialog_contents,
.cke_dialog_footer {
    @apply dark:bg-gray-900 dark:text-white dark:border-gray-700 !important;
}

input.cke_dialog_ui_input_text,
input.cke_dialog_ui_input_password,
input.cke_dialog_ui_input_tel,
textarea.cke_dialog_ui_input_textarea {
    @apply dark:bg-gray-900 dark:text-white dark:border-gray-700 !important;
}

select.cke_dialog_ui_input_select {
    @apply dark:bg-gray-900 dark:text-white dark:border-gray-700 !important;
}

.ck-content > blockquote,
.ck-content > dl,
.ck-content > dd,
.ck-content > h1,
.ck-content > h2,
.ck-content > h3,
.ck-content > h4,
.ck-content > h5,
.ck-content > h6,
.ck-content > hr,
.ck-content > figure,
.ck-content > p,
.ck-content > pre {
    margin: revert;
}

.ck-content > ol,
.ck-content > ul {
    list-style: revert;
    margin: revert;
    padding: revert;
}

.ck-content > li {
    margin-top: 3px;
}

.ck-content > table {
    border-collapse: revert;
}

.ck-content > h1,
.ck-content > h2,
.ck-content > h3,
.ck-content > h4,
.ck-content > h5,
.ck-content > h6 {
    font-size: revert;
    font-weight: revert;
}

@import "./common/reset.css";
@import "./common/scrollbar.css";
@import "./common/colors.css";
@import "./common/contents.css";
@import "./common/animations.css";
@import "./common/helper.css";
@import "./common/header.css";
@import "./common/lists.css";
@import "./common/modal.css";
@import "./common/buttons.css";
@import "./common/easy_progress.css";
@import "./common/modules-progress.css";
@import "./common/ckeditor.css";
@import "./common/video-player.css";
@import "./common/pagination.css";
@import "./common/datatable.css";
@import "./common/form.css";
@import "./common/flatpickr.css";
@import "./common/flashbags.css";
@import "./common/select2.css";
@import "./common/popup.css";
@import "./common/waves.css";
@import "./_admin/table.css";

/* Home */

.tooltip {
    z-index: 9999;
}

.tooltip-inner {
    min-width: 40px;
}

/* Tools */
.block-on-white {
    @apply rounded border p-6 m-0 dark:border-gray-700;
}

/* Bootstrap */

a.collapsed {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
    outline: 0;
}

.panel-group {
    margin-top: 30px;
}

.panel-title {
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
}

.score-block h4 {
    font-size: 16px;
}

.panel-indicator {
    display: none;
    float: right;
}

.panel-indicator i {
    color: #333;
}

a.collapsed + h4 {
    color: #333;
}

a.collapsed:hover + h4 + .panel-indicator {
    display: inline-block;
}

.question .panel:nth-child(2) h4,
.question .panel:nth-child(3) h4 {
    margin-left: -15px;
    padding: 5px 15px;
    border-radius: 2px;
    border: 1px solid transparent;
}

.question .panel:nth-child(2):hover a[aria-expanded="false"] + h4,
.question .panel:nth-child(3):hover a[aria-expanded="false"] + h4 {
    padding: 5px 15px;
    border-radius: 2px;
    border: 1px solid #bbb;
}

#question {
    transition: all 0.05s ease-in-out;
}

/* Force style for dropdown button menu */
button.nav-link {
    line-height: unset !important;
}

.add_link:focus,
.add_link:hover {
    @apply transition text-black dark:text-gray-700;
}

/* Responsive */

@media (max-width: 992px) {
    /* Footer */
    footer .links span {
        display: block;
    }

    /* Nav */
    #connection > span {
        line-height: 30px;
        margin-right: 10px;
    }
}

@media (max-width: 767px) {
    .btn-primary,
    .btn-secondary,
    .btn-alternative,
    .btn-no-border {
        margin-bottom: 5px;
    }

    /* header */
    #connection-submenu {
        font-size: 1.3rem;
        left: 50%;
        width: 90%;
        margin-left: -45%;
    }

    .connection-submenu ul::before {
        right: 33px;
    }

    /* Home */
    #home-header {
        margin-bottom: 20px;
    }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

:focus:not(:focus-visible) {
    outline: 0 !important;
    box-shadow: none;
}

.focus-visible:focus:not(:focus-visible),
:focus {
    outline: 0;

    --tw-ring-color: 0;

    @apply border-black dark:border-gray-700;

    box-shadow:
        0 0 0 0.2rem #fff,
        0 0 0 0.35rem #069;
}

[type="checkbox"]:focus-visible,
[type="radio"]:focus-visible,
:focus-visible {
    outline: 0;
    box-shadow:
        0 0 0 0.2rem #fff,
        0 0 0 0.35rem #069;
}

.m-0 {
    margin: 0 !important;
}

/* Buttons */
.btn-primary,
.btn-no-border {
    border: 0;
    color: #fff;
    background: #111;
    border-radius: 2px;
    height: 50px;
    padding: 0 30px;
    line-height: 30px;
    display: block;
    font-size: 1.5rem;
    transition: all 0.1s ease-in-out;
}

.btn-primary:link,
.btn-no-border:link,
.btn-primary:visited,
.btn-no-border:visited,
.btn-primary:hover,
.btn-no-border:hover,
.btn-primary:focus,
.btn-no-border:focus,
.btn-primary:active,
.btn-no-border:active {
    background: #000 !important;
    color: #fff !important;
    transform: scale(1.03);
    box-shadow: 0 0 15px rgb(0 0 0 / 10%);
}

.btn-no-border,
.btn-no-border:hover {
    background: transparent;
    color: black;
}

.btn-secondary {
    color: #000;
    border: 1px solid #000;
    background: transparent;
    border-radius: 2px;
    height: 50px;
    padding: 0 30px;
    line-height: 30px;
    display: block;
    font-size: 1.5rem;
}

.btn-secondary:hover {
    background: #000;
    color: #fff;
}

.btn-alternative {
    background: transparent;
    border: none;
    color: #2d82c5;
}

.btn-question-inverted {
    border-color: #000;
    color: #000;
    transition: all 0.1s ease-in-out;
}

.btn-question-inverted:hover {
    border-color: #000;
    color: #000;
    background-color: inherit;
    transform: scale(1.01);
    box-shadow: 0 0 15px rgb(0 0 0 / 10%);
}

.btn-form-save {
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 12px;
    line-height: 1.1;
    font-weight: 700;
    color: rgb(74 59 133);
    border: 1px solid transparent;
    border-radius: 3px;
    padding: 5px 12px;
}

.btn-form-save:hover {
    background-color: #ff364e;
    color: #fff;
}

.inverted-link {
    color: white;
}

.inverted-link:hover {
    color: white;
}

.copy-input {
    padding: 10px;
    width: 100%;
    @apply border border-black rounded bg-gray-100 dark:bg-gray-900 dark:border-gray-700;
}

a.modules-big:hover.not-for-tester,
a:hover.not-for-tester .practice-block {
    /* transform: inherit; */
    transform: scale(1.01);
}

a.not-for-tester {
    opacity: 0.4;
    filter: blur(2px);
}

a.not-for-tester .practice-block {
    opacity: 0.4;
    border-color: #bbb;
    filter: blur(2px);
}

a.not-for-tester:hover {
    cursor: pointer;

    /* opacity:.4; */
    filter: blur(0);
}

a.not-for-tester:hover .practice-block {
    border-color: #ddd;
    filter: blur(0);
    opacity: 0.7;
}

.disabled {
    pointer-events: none;
}

#step-text-render a {
    color: #0371ff;
    text-decoration: underline;
}

.tailwind-button {
    padding-top: 0.6em;
    padding-bottom: 0.6em;
}

input[type="file"] {
    padding: 0;
    border: none;
    @apply tailwind-button small;
}

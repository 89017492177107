/* Popup */
.badge-popup {
    text-align: center;
    max-width: 550px;
    margin: 100px auto 0;
    overflow: hidden;
    padding: 20px 30px 50px;
    position: relative;
    transition: all 1s ease;
    width: auto;

    @apply bg-white shadow rounded dark:bg-gray-900 dark:border dark:border-gray-700;
}

.assessment-popup {
    text-align: center;
    background: #fff;
    max-width: 868px;
    margin: 212px auto 0;
    border-radius: 2px;
    overflow: hidden;
    padding: 20px 30px 50px;
    position: relative;
    transition: all 1s ease;
}

.badge-popup.modal-lg {
    max-width: 550px;
    padding: 20px 30px 50px;
}

.badge-popup.dropzone-popup {
    max-width: 550px;
    min-height: 400px;
}

.badge-popup h4 {
    padding: 0 10px;
    margin-top: 20px;
    @apply text-xl;
}

.badge-popup p {
    padding: 0 10px;
    margin-top: 10px;
}

.badge-popup div {
    text-align: center;
}

.badge-popup .popup-icons {
    margin: 50px 0;
    height: 100px;
}

.badge-popup div img {
    width: 57%;
    margin-top: -25px;
}

.badge-popup div i {
    display: inline-block;
    position: relative;
    font-size: 70px;
    margin: 0 8%;
    top: 50%;
    margin-top: -35px;
}

.badge-popup .button {
    margin: 0 auto;
    margin-top: 0;
    display: inline-block;
}

.badge-popup .button i {
    font-size: 10px;
}

.badge-popup .button.center {
    display: inline-block;
}

.badge-popup .button.center i {
    margin-left: 10px;
}

.badge-popup .button.left {
    float: left;
    margin-left: 20px;
}

.badge-popup .button.left i {
    margin-right: 10px;
}

.badge-popup .button.right {
    float: right;
    margin-right: 20px;
}

.badge-popup .button.right i {
    margin-left: 10px;
}

/* Help Modal */
.help-popup {
    text-align: center;
    background: #fff;
    width: auto;
    max-width: 500px;
    margin: 100px auto 0;
    border-radius: 2px;
    overflow: hidden;
    padding: 40px;
    position: relative;
    transition: all 1s ease;
}

.help-popup h3 {
    font-size: 18px;
    color: #ff364e;
    font-weight: 600;
}

.help-popup p {
    margin-top: 35px;
    color: #111;
}

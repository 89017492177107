/* Select2 */
.select2-container {
    width: 100% !important;
}

.select2-container *:focus {
    outline: none;
    border-color: #000;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 40px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 40px;
    @apply dark:text-white;
}

.select2-container--open span.selection span.select2-selection.select2-selection--single {
    @apply border-gray-400 dark:border-gray-700;
}

.select2-container--default .select2-selection--single {
    background: none;
    border-radius: 1px;
    @apply border-gray-400 dark:border-gray-700;
}

.select2-dropdown {
    @apply border-gray-400 dark:border-gray-700;
}

.select2-container--open .select2-dropdown {
    @apply border-black dark:border-gray-700;
}

.select2-results__option.select2-results__option--highlighted {
    @apply bg-black !important;
}

.select2-container--default .select2-results__option {
    @apply dark:bg-gray-900;
}

.select2-search--dropdown {
    @apply dark:bg-gray-900;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    @apply dark:bg-gray-900 dark:border-gray-700;
}

.select2-container--default .select2-selection--multiple {
    @apply rounded dark:bg-gray-900 dark:border-gray-700;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    @apply dark:bg-gray-900 dark:text-white dark:border-gray-700;
}

span.select2-selection.select2-selection--single {
    height: 45px;
}

.select2-container .select2-selection--multiple {
    min-height: 40px;
}
